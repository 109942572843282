import React from "react";
import { graphql } from "gatsby";
import {
  BarChart,
  Bar,
  // Cell,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  Legend,
  // ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
import Layout from "../components/Layout";
import ContentWrapper from "../components/ContentWrapper";
import SEO from "../components/SEO";

const Main = styled.div`
  min-height: 30vh;
  padding: 1rem;
  color: white;
`;

const TitleName = styled.h2`
  padding: 1rem;
  border-bottom: 1px solid #999999;
  font-size: 2rem;
  font-weight: 700;
`;

const GraphSection = styled.div`
  padding: 1rem;
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 2rem;
`;

const StatsPage = ({ data }) => {
  const dataset = data.allSeasonStatsJson.nodes;

  return (
    <Layout title="Stats">
      <SEO pagetitle="Stats" pagedesc="Stats について" />
      <ContentWrapper>
        <Main>
          <GraphSection>
            <TitleName>棋士の年齢内訳</TitleName>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <ChartWrapper>
              <BarChart
                width={1000}
                height={500}
                data={dataset}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="season" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="10代" dataKey="age_10" stackId="a" fill="#3BAFE5" />
                <Bar name="20代" dataKey="age_20" stackId="a" fill="#95D9F2" />
                <Bar name="30代" dataKey="age_30" stackId="a" fill="grey" />
                <Bar name="40代" dataKey="age_40" stackId="a" fill="#FFD573" />
                <Bar name="50代" dataKey="age_50" stackId="a" fill="#FFB400" />
                <Bar name="60代" dataKey="age_60" stackId="a" fill="#FE7701" />
                <Bar
                  name="70代以上"
                  dataKey="age_70"
                  stackId="a"
                  fill="#3BAFE5"
                />
              </BarChart>
            </ChartWrapper>
            {/* </ResponsiveContainer> */}
          </GraphSection>
          <GraphSection>
            <TitleName>棋士の段位内訳</TitleName>
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <ChartWrapper>
              <BarChart
                width={1000}
                height={500}
                data={dataset}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="season" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="四段" dataKey="level4" stackId="a" fill="#3BAFE5" />
                <Bar name="五段" dataKey="level5" stackId="a" fill="#95D9F2" />
                <Bar name="六段" dataKey="level6" stackId="a" fill="grey" />
                <Bar name="七段" dataKey="level7" stackId="a" fill="#FFD573" />
                <Bar name="八段" dataKey="level8" stackId="a" fill="#FFB400" />
                <Bar name="九段" dataKey="level9" stackId="a" fill="#FE7701" />
              </BarChart>
              {/* </ResponsiveContainer> */}
            </ChartWrapper>
          </GraphSection>
        </Main>
      </ContentWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allSeasonStatsJson {
      nodes {
        age_0
        age_10
        age_20
        age_30
        age_40
        age_50
        age_60
        age_70
        age_all
        level4
        level5
        level6
        level7
        level8
        level9
        level_all
        season
      }
    }
  }
`;

export default StatsPage;
